body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f7f9;
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f4f7f9;
}

h1 {
  color: #2a475e;
  margin-bottom: 16px;
}

p {
  color: #444;
  margin: 8px;
  font-size: 18px;
}

input {
  padding: 12px 20px;
  margin: 16px;
  border: none;
  border-radius: 12px;
  background-color: #ffffff;
  font-size: 16px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.btn {
  cursor: pointer;
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 12px 2px;
  transition-duration: 0.4s;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.btn:hover {
  background-color: #45a049;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
}

.scontainer {
  /* Existing styles */
  display: flex;
  justify-content: space-between;
}

span {
  flex: 1;
  text-align: center;
}

input[type="text"] {
  width: 90%;
  font-size: 18px;
  padding: 10px;
  margin-bottom: 10px;
}

button.btn-sm {
  font-size: 18px;
  padding: 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button.btn {
  width: 90%;
  font-size: 18px;
  padding: 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
  font-size: 16px; /* Increased Font Size */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  width: 80%;
  margin: auto;
  padding: 20px;
  border-radius: 8px;
  background-color: #f5f5f5;
  font-size: 18px; /* Increased Font Size */
}

.action-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  flex-direction: column; /* stack elements vertically */
}

.action-group input {
  margin: 10px;
  padding: 15px; /* Increased Padding */
  font-size: 16px; /* Increased Font Size */
  width: 100%;  /* full width */
}

.action-group button {
  padding: 15px 30px; /* Increased Padding */
  font-size: 16px; /* Increased Font Size */
  background-color: #007bff; /* Background Color */
  color: white; /* Text Color */
  border: none; /* Remove Border */
  border-radius: 5px; /* Rounded Corners */
  cursor: pointer; /* Cursor on Hover */
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: grey;
  font-size: 14px; /* Increased Font Size */
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.9);
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.container {
  width: 80%;
  margin: auto;
  padding: 20px;
  border-radius: 8px;
  background-color: #f5f5f5;
}

.action-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
}

.action-group input {
  margin-right: 10px;
  padding: 10px;
}

.action-group button {
  padding: 10px 20px;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: grey;
  font-size: 12px;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.9);
}

